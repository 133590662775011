import React from 'react'
import { graphql } from 'gatsby'
import SEO from '../components/seo'
import styled from 'styled-components'
import Layout from './../components/layout'

const Container = styled.div`
	width: 100%;

	padding-bottom: 50px;
`

const Grid = styled.div`
	width: 1200px;
	margin: 0 auto;

	@media (max-width: 1200px) {
		width: 90%;
	}
`

const Title = styled.h1`
	margin: 0;
	position: relative;
	font-size: 40px;
	font-weight: bold;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.32;
	letter-spacing: normal;
	text-align: left;
	color: ${props => props.theme.colors.black_fonts};

	margin-bottom: 60px;
	padding-top: 30px;

	&:after {
		content: '';
		position: absolute;
		bottom: -10px;
		left: 0;
		width: 90px;
		height: 5px;
		background: ${props => props.theme.colors.primary};

		@media (max-width: 767.98px) {
			height: 4px;
		}
	}

	@media (max-width: 767.98px) {
		font-size: 28px;
		margin: 0 12px 0 12px;
	}

	@media (min-width: 768px) and (max-width: 991.98px) {
		font-size: 40px;
	}

	@media (min-width: 992px) and (max-width: 1199.98px) {
		font-size: 50px;
	}
`

const DireitosDosPacientes = ({ location, data }) => (
	<Layout>
		<SEO
			title={data.cosmicjsInformacoesEstaticas.metadata.titulo}
			description={data.cosmicjsInformacoesEstaticas.metadata.titulo}
		/>

		<Container>
			<Grid>
				<Title id='title-news-details'>{data.cosmicjsInformacoesEstaticas.metadata.titulo}</Title>

				<div dangerouslySetInnerHTML={{ __html: data.cosmicjsInformacoesEstaticas.metadata.conteudo }} />
			</Grid>
		</Container>
	</Layout>
)

export const query = graphql`
	query {
		cosmicjsInformacoesEstaticas(slug: { eq: "direitos-dos-pacientes-land" }) {
			metadata {
				titulo
				conteudo
			}
		}
	}
`

export default DireitosDosPacientes
